// import * as THREE from 'three';
// import * as dat from 'dat.gui';
//
//
// import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

//three js animation



const initThree = () => {

    let scene = new THREE.Scene();

    let container = document.querySelector('.containerAnimate');
    let renderer = new THREE.WebGLRenderer({antialias: true});
    let width = (container.offsetWidth);
    let height = (container.offsetHeight);
    console.log(width,height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.setSize(width, height);
    renderer.setClearColor(0x000000, 1);
    renderer.physicallyCorrectLights = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.autoClear = true;

    // renderer.setClearColorHex( 0xffffff, 1 );

    container.appendChild(renderer.domElement);

    let camera = new THREE.PerspectiveCamera(
        70,
        width / height,
        0.001,
        100000
    );
    let time = 0;

    let controls = new OrbitControls( camera, container );

}
// setTimeout(() => {
//     initThree();
//
// },50)


//Slider всего сайта.
let activeScreen = 1;
let screenAll = 0;
let timeSlideNext = null;
let statusSlideNext = true;

let menuItem = document.querySelectorAll('.topMenuLi');

let elemScreen = document.querySelectorAll('.slideItem');
let activePageNumber = document.querySelector('.numberActive');
let allPageNumber = document.querySelector('.numberActiveAll');
let trackActive = document.querySelector('.lineActivePage');
let trackContainer = document.querySelector('.lineBlock').clientWidth;

screenAll = elemScreen.length - 1;
trackActive.style.width = trackContainer / (screenAll + 1 )  + 'px';
trackActive.style.left = ((trackContainer / (screenAll + 1)) * activeScreen) + 'px';

activePageNumber.innerHTML = '0' + (activeScreen + 1)
allPageNumber.innerHTML = '0' + (screenAll + 1)
elemScreen[activeScreen].style.display = 'block'

document.addEventListener('wheel', (e)=>{

    if(!statusSlideNext) return
    timeSlideNext = setTimeout(()=> {
        statusSlideNext = true;
    }, 500)
    statusSlideNext = false;

    let action = e.deltaY > 0 ? 'next':'prev';
    if(activeScreen === 0 && action === 'prev') return
    if(activeScreen === screenAll && action === 'next') return


    if(action === "next") {

        elemScreen[activeScreen].style.display = 'none'
        activeScreen++
        elemScreen[activeScreen].style.display = 'block'
    }
    if(action === "prev") {
        elemScreen[activeScreen].style.display = 'none'
        activeScreen--
        elemScreen[activeScreen].style.display = 'block'
    }
    activePageNumber.innerHTML = '0' + (activeScreen + 1)
    trackActive.style.left = ((trackContainer / (screenAll + 1)) * activeScreen) + 'px';
})

menuItem.forEach((el,index)=> {
    el.addEventListener('click', ()=> {
        menuItem.forEach(el=> {
            el.classList.remove('active');
        });
        el.classList.add('active');

        elemScreen[activeScreen].style.display = 'none'
        activeScreen = index + 1;
        elemScreen[activeScreen].style.display = 'block'
        activePageNumber.innerHTML = '0' + (activeScreen + 1)
        trackActive.style.left = ((trackContainer / (screenAll + 1)) * activeScreen) + 'px';
    })
})
let backHome = document.querySelectorAll('.logoLink');
backHome.forEach((el,index)=> {
    el.addEventListener('click', ()=> {
        elemScreen[activeScreen].style.display = 'none'
        activeScreen = 0;
        elemScreen[activeScreen].style.display = 'block'
        activePageNumber.innerHTML = '0' + (activeScreen + 1)
        trackActive.style.left = ((trackContainer / (screenAll + 1)) * activeScreen) + 'px';
    })
})


//Slider услуг.
let activeScreenService = 0;
let screenAllService = 0;
let timeSlideNextService = null;
let statusSlideNextService = true;

let elemScreenService = document.querySelectorAll('.itemSliderService');
screenAllService = elemScreenService.length - 1;
elemScreenService[activeScreenService].style.display = 'block'
let contSlideService = document.querySelector('.rightBlockSlider');
let contCircle = document.querySelector('.trackSlider');

elemScreenService.forEach((el)=> {
    let div = document.createElement('div');
    div.className = 'circle';
    contCircle.prepend(div)
})
let allCircle = document.querySelectorAll('.circle');
allCircle[activeScreenService].classList.add("circleActive");

contSlideService.addEventListener('wheel', (e)=> {
    e.stopPropagation();

    if(!statusSlideNextService) return
    timeSlideNextService = setTimeout(()=> {
        statusSlideNextService = true;
    }, 500)
    statusSlideNextService = false;

    let action = e.deltaY > 0 ? 'next':'prev';
    if(activeScreenService === 0 && action === 'prev') return
    if(activeScreenService === screenAllService && action === 'next') return
    if(action === "next") {
        elemScreenService[activeScreenService].style.display = 'none'
        activeScreenService++
        elemScreenService[activeScreenService].style.display = 'block'
    }
    if(action === "prev") {
        elemScreenService[activeScreenService].style.display = 'none'
        activeScreenService--
        elemScreenService[activeScreenService].style.display = 'block'
    }
    allCircle.forEach(el=> {
        el.classList.remove('circleActive')
    })
    allCircle[activeScreenService].classList.add("circleActive");
})


//Отпрвка формы

let form = {
    name: '',
    secondName: '',
    email: '',
    phone: '',
    question: '',
}

const sendEmail = () =>{
    form.name = document.querySelector('.nameInput').value;
    form.secondName = document.querySelector('.secondNameInput').value;
    form.email = document.querySelector('.emailInput').value;
    form.phone = document.querySelector('.phoneInput').value;
    form.question = document.querySelector('.questionInput').value;

    if ("" !== form.email || "" !== form.phone) {
        try {
            let t = new XMLHttpRequest
                , o = JSON.stringify(form);
            t.open("POST", "/send.php", !0)
            t.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
            t.send(o)
            alert("Ожидайте ответа специалиста")
        } catch (t) {
            alert("Произошла ошибка")
        }
    } else {
        alert("Укажите предпочитаемый вид связи")
    }
}

let buttonSend = document.querySelector('.sendButton');
buttonSend.addEventListener('click', sendEmail)
